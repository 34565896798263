import { IconRecurring } from '../../../../../../components/Icons/IconRecurring'
import formatters from '../../../../../../lib/formatters'
import SectionHeader from '../../Header/SectionHeader'
import { requestTypeToValue } from '../constant'
import styles from './styles'

export default function ViewDrf(props) {
  const { form, drfType, accounts, setEdit } = props

  const requestor = accounts?.find((contact) => contact.id === form.requestorId)

  console.log('requestType', requestTypeToValue[form.requestType])

  return (
    <div className={styles.viewDrf}>
      <div className={styles.cardMarginBot}>
        <SectionHeader
          title={'Invoice Review View'}
          extra={
            <div className={styles.editButton} onClick={() => setEdit(true)}>
              Edit Details
            </div>
          }
          className={styles.invoiceReviewHeader}
        />
        <div className={styles.cardMarginBotWrapper}>
          <div className={styles.sectionWrapper}>
            <section className={styles.sectionNoMargin}>
              <div className={styles.subSection}>
                <p className={styles.subSectionLabel}>Who is making the request?</p>
                <p className={styles.subSectionValue}>
                  {requestor?.name} - {requestor?.role}
                </p>
              </div>
            </section>
            <section className={styles.sectionNoMargin}>
              <div className={`${styles.subSection} ${styles.subSectionRight}`}>
                <p className={styles.subSectionLabel}>Date Received</p>
                <p className={styles.subSectionValue}>{formatters.date(form.dateReceived)}</p>
              </div>
            </section>
          </div>
          <div className={styles.sectionWrapper}>
            <section className={styles.sectionNoMargin}>
              <div className={styles.subSection}>
                <p className={styles.subSectionLabel}>Pay to</p>
                <p className={styles.subSectionValue}>
                  <div className={styles.payTo}>
                    <div className={styles.xeroItem}>
                      <div className={styles.payToAddress}>
                        <span className={styles.subText}>
                          {form.xeroPayTo.name} {form.xeroPayTo.street}
                        </span>
                        {form.xeroPayTo.city || form.xeroPayTo.state ? (
                          <span className={styles.subText}>
                            {form.xeroPayTo.city}, {form.xeroPayTo.state} {form.xeroPayTo.zip}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </p>
              </div>
            </section>
            <section className={styles.sectionNoMargin}>
              <div className={`${styles.subSection} ${styles.subSectionRight}`}>
                <p className={styles.subSectionLabel}>Reference</p>
                <p className={styles.subSectionValue}>{form.reference}</p>
              </div>
            </section>
          </div>
        </div>

        <div className={styles.cardMarginBotWrapperBottom}>
          <div className={`${styles.invoiceBottom} ${styles.header}`}>
            <p className={styles.desc}>Description</p>
            <p className={styles.req}>Request Type</p>
            <p className={styles.pay}>Payment Type</p>
            <p className={styles.amt}>Request Amount</p>
          </div>
          <div className={styles.invoiceBottom}>
            <p className={styles.desc}>{form.description}</p>
            <p className={styles.req}>{form.requestType && requestTypeToValue[form.requestType]}</p>
            <p className={styles.pay}>{form.paymentType}</p>
            <p className={styles.amt}>{form.amount}</p>
          </div>
          {
            <div className={styles.recurringInfo}>
              Requerring Payment Requested <IconRecurring />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

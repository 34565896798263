import Header from '../../Header/Header'
import styles from '../styles.css'

export default function ReviewForm({ form, checkingBalance, setCheckingBalance }) {
  return (
    <div className={`${styles.sectionThree} ${styles.sectionThreeTop}`}>
      {form.account && (
        <Header
          number={form.account.number}
          beneficiary={form.account.beneficiary}
          otherDistributions={form.otherDistributions}
          checkingBalance={checkingBalance}
          setCheckingBalance={setCheckingBalance}
          touchNav
        />
      )}
    </div>
  )
}

import formatters from 'lib/formatters'
import { ActionsEnum, DRF_TYPE, recurringStatus } from './constant'
import styles from './forms/styles.css'

export const main = ['written', 'admin_reviewed', 'signer_reviewed', 'admin_wrapped_up', 'approved_not_paid', 'paid_today']
export const pending = ['pending_client', 'pending_internal', 'aged_pending', 'pending']
const paid = ['paid']
export const denied = ['denied', 'denied_closed']
export const recurring = [
  'recurring_received',
  'recurring_written',
  'recurring_reviewed',
  'recurring_approved',
  'scheduled',
  'expired',
  'recurring_expired',
]

export const getColor = (nextStatus) => {
  if (main.includes(nextStatus)) {
    return 'cyan'
  } else if (pending.includes(nextStatus)) {
    return 'amber'
  } else if (paid.includes(nextStatus)) {
    return 'lime'
  } else if (denied.includes(nextStatus)) {
    return 'orange'
  } else if (recurring.includes(nextStatus)) {
    return 'purple'
  }
  return 'cyan'
}

// date should be in MM/DD/YY
export const getStepper = ({ form, events }) => {
  const mainStepper = [
    {
      label: 'Receive',
      date: formatters.date(form.dateReceived),
    },
    {
      label: 'Write',
      date: formatters.date(getStatusByDescription(events, 'changed status to written')),
    },
    {
      label: (
        <div>
          Admin
          <br /> Reviewed
        </div>
      ),
      date: formatters.date(getStatusByDescription(events, 'changed status to admin reviewed')),
    },
    {
      label: (
        <div>
          Signer
          <br /> Review
        </div>
      ),
      date: formatters.date(getStatusByDescription(events, 'changed status to signer reviewed')),
    },
    {
      label: 'Approve',
      date: formatters.date(getStatusByDescription(events, 'Changed status to Paid')),
    },
  ]

  return mainStepper
}

export const setActiveStep = (currentStatus) => {
  switch (currentStatus) {
    case 'received':
      return 1
    case 'written':
      return 2
    case 'admin_reviewed':
      return 3
    case 'signer_reviewed':
      return 4
    case 'admin_wrapped_up':
      return 5
    case 'paid':
      return 6
    default:
      return null
  }
}

export const getHeaderBasedOnEvents = (events, currentStatus, form) => {
  let steps = events
    ?.filter((e) => e.description.includes('Changed status'))
    ?.map((e, idx) => ({
      key: `${e.description.replace('Changed status to ', '')}-${idx}`,
      label: e.description.replace('Changed status to ', ''),
      date: formatters.date(e.createdAt),
    }))
    .reverse()
  let stepsLength = steps?.length - 1
  const findLabel = (label) => steps.findIndex((step) => step.label == label) == -1

  switch (currentStatus) {
    case 'pending':
      if (findLabel('Pending')) {
        steps.push({
          label: 'Pending',
          date: null,
        })
      }
      stepsLength += steps.length + 1
      break
    case 'denied':
      if (findLabel('Denied')) {
        steps.push({
          label: 'Denied',
          date: null,
        })
      }
      stepsLength += 1
      break
    case 'denied_closed':
      if (findLabel('Denied Closed')) {
        steps.push({
          label: 'Closed',
          date: null,
        })
      } else {
        steps = steps.map((step) =>
          step.label == 'Denied Closed'
            ? {
                label: 'Closed',
                date: step?.date,
              }
            : step,
        )
      }
      stepsLength += steps.length + 1
      break
    case 'recurring_expired':
      steps.push({
        label: 'Recurring Expired',
        date: null,
      })
      stepsLength = steps.length + 1
      break
    case 'paid':
      stepsLength = steps.length + 1
      break
  }

  return {
    steps: [
      {
        label: 'Receive',
        date: formatters.date(form.dateReceived),
      },
      ...steps,
    ],
    activeStep: stepsLength,
  }
}

export const getHeader = ({ headerTitle, currentStatus, nextStatus, form, id, events, currentTable }) => {
  let stepper = null

  if (recurringStatus.includes(currentStatus)) {
    stepper = getRecurringHeader(currentStatus, nextStatus, form, events)
  }
  if ([...paid, ...pending, ...denied].includes(currentStatus)) {
    stepper = getHeaderBasedOnEvents(events, currentStatus, form)
  }

  return {
    title: headerTitle,
    stepper: {
      steps: stepper != null ? stepper.steps : getStepper({ nextStatus, form, events }),
      activeStep: stepper != null ? stepper.activeStep : setActiveStep(currentStatus),
    },
    id: id,
    idTitle: stepper?.idTitle ?? 'ID',
  }
}

export const getRecurringHeader = (currentStatus, nextStatus, form, events) => {
  let activeStep = 0

  const getStepStatus = () => {
    switch (currentStatus) {
      case 'recurring_approved':
        activeStep = 3
        break
      case 'recurring_expired':
        activeStep = 5
    }
  }

  switch (nextStatus) {
    case 'recurring_written':
      activeStep = 0
      break
    case 'recurring_reviewed':
      activeStep = 1
      break
    case 'recurring_approved':
      activeStep = 2
      break
    case null:
      getStepStatus()
      break
  }

  const receivedDate =
    formatters.date(getStatusByDescription(events, 'changed status to recurring received')) ??
    formatters.date(getStatusByDescription(events, 'changed status to recurring written'))

  const steps = [
    { label: 'Receive', date: receivedDate },
    {
      label: (
        <div>
          Sr. Admin <br />
          Reviewed
        </div>
      ),
      date: formatters.date(getStatusByDescription(events, 'changed status to recurring reviewed')),
    },
    {
      label: 'Signer Review',
      date: formatters.date(getStatusByDescription(events, 'changed status to recurring reviewed')),
    },
    {
      label: 'Approve',
      date: formatters.date(getStatusByDescription(events, 'approved')),
    },
  ]
  if (activeStep == 5) {
    steps.push({
      label: 'Expired',
      date: formatters.date(getStatusByDescription(events, 'recurring expired')),
    })
  }
  const stepper = {
    steps,
    activeStep,
    idTitle: 'TEMPLATE ID',
  }

  return stepper
}

export function areAllRequiredActionsCompleted(completedActions, requiredActions) {
  return requiredActions.every((action) => completedActions.includes(action))
}

const getStatusByDescription = (events, key) => {
  for (let i = 0; i < events.length; i++) {
    const description = events[i].description.toLowerCase()
    if (description.includes(key.toLowerCase())) {
      return events[i].createdAt
    }
  }

  return null
}

export const isMainQueue = function (currentStatus) {
  return ['received', ...main].includes(currentStatus)
}

const getWhatDrf = (drfType) => {
  switch (drfType) {
    case DRF_TYPE.SCHEDULED:
      return 'SCHEDULED DRF'
    case DRF_TYPE.TEMPLATE:
      return 'RECURRING TEMPLATE'
    case DRF_TYPE.STANDARD:
      return 'DRF'
  }
}

export const getStatusBasedText = (nextStatus, status, drfType) => {
  let headerTitle = ''
  let approveButtonText = ''
  let disapproveButtonText = 'Unable to Process at this Time'

  const WHAT = getWhatDrf(drfType)

  switch (nextStatus) {
    case 'written':
      headerTitle = ActionsEnum.WRITE
      approveButtonText = ActionsEnum.READY_TO_REVIEW
      break
    case 'admin_reviewed':
      headerTitle = ActionsEnum.ADMIN_REVIEW
      approveButtonText = ActionsEnum.APPROVE
      break
    case 'signer_reviewed':
      headerTitle = ActionsEnum.SIGNER_REVIEW
      approveButtonText = ActionsEnum.APPROVE
      break
    case 'admin_wrapped_up':
      headerTitle = ActionsEnum.ADMIN_WRAP_UP
      approveButtonText = ActionsEnum.PAID
      break
    case 'paid':
      headerTitle = ActionsEnum.APPROVE_NOT_PAID
      approveButtonText = ActionsEnum.SAVE_UPDATES
      break
    case 'recurring_written':
      headerTitle = ActionsEnum.WRITE
      approveButtonText = ActionsEnum.APPROVE
      break
    case 'recurring_reviewed':
      headerTitle = ActionsEnum.SR_ADMIN_REVIEW
      approveButtonText = ActionsEnum.READY_TO_REVIEW
      break
    case 'recurring_approved':
      headerTitle = ActionsEnum.SIGNER_REVIEW
      approveButtonText = ActionsEnum.APPROVE
      break
    case 'pending':
      headerTitle = ActionsEnum.PENDING
      approveButtonText = ActionsEnum.READY_TO_PROCESS
      disapproveButtonText = ActionsEnum.CLOSE_REQUEST
      break
    case 'aged_pending':
      headerTitle = ActionsEnum.AGED_PENDING
      approveButtonText = ActionsEnum.READY_TO_PROCESS
      disapproveButtonText = ActionsEnum.CLOSE_REQUEST
      break
    case 'denied_closed':
      headerTitle = ActionsEnum.DENIED_CLOSED_DRF
      break
    default:
      headerTitle = ActionsEnum.SAVE_REQUEST
      approveButtonText = ActionsEnum.SAVE_UPDATES
  }

  if (nextStatus == null) {
    // use current status
    switch (status) {
      case 'pending':
      case 'aged_pending':
        headerTitle = ActionsEnum.PENDING
        approveButtonText = ActionsEnum.READY_TO_PROCESS
        disapproveButtonText = ActionsEnum.CLOSE_REQUEST
        break
      case 'aged_pending':
        headerTitle = ActionsEnum.AGED_PENDING
        approveButtonText = ActionsEnum.READY_TO_PROCESS
        disapproveButtonText = ActionsEnum.CLOSE_REQUEST
        break
      case 'paid':
        headerTitle = ActionsEnum.PAID
        break
      case 'denied':
        headerTitle = ActionsEnum.DENIED_DRF
        approveButtonText = ActionsEnum.CLOSE_REQUEST
        break
      case 'denied_closed':
        headerTitle = ActionsEnum.DENIED_CLOSED_DRF
        break
      case 'recurring_expired':
        headerTitle = ActionsEnum.EXPIRED
        break
      case 'recurring_approved':
        headerTitle = ActionsEnum.APPROVED
      default:
        disapproveButtonText = 'Unable to Process at this Time'
    }
  }

  return {
    headerTitle: `${headerTitle} - ${WHAT}`,
    approveButtonText,
    disapproveButtonText,
  }
}

export const getCalculation = ({ amount, checkingBalance, openTotal, pendingTotal, hasBudget, floorAmount }) => {
  if (amount == null) {
    return { message: null, styles: null, disableCta: false }
  }

  if (checkingBalance != null) {
    checkingBalance = Number(formatters.inputMoney(checkingBalance))
  }

  let requestCalcuation = checkingBalance - amount
  openTotal = Number(openTotal)
  pendingTotal = Number(pendingTotal)
  if (!isNaN(openTotal)) {
    requestCalcuation = requestCalcuation - openTotal
  }
  if (!isNaN(pendingTotal)) {
    requestCalcuation = requestCalcuation - pendingTotal
  }

  if (amount > checkingBalance || (floorAmount > 0 && amount > floorAmount)) {
    // Case I
    // Amount is greater than displayed Main Account Balance(checkingBalance)
    // When Amount is great than the floor on account
    return { message: 'Not enough funds to process', styles: styles.danger, disableCta: true }
  } else if (hasBudget) {
    // Case II
    // There is a budget on the account.
    return { message: 'Check account budget', styles: styles.orange, disableCta: false }
  } else if (requestCalcuation < 0) {
    // Case III
    // There total Open Requests and/or Pending Requests is larger than the Main Account Balance.
    // Main Account Balance - Total Open - Total Pending - Request Amount > 0

    return { message: 'Check account funds and DRFs in Process', styles: styles.orange, disableCta: false }
  }

  return { message: null, styles: null, disableCta: false }
}

import XeroLink from 'components/XeroLink'
import React from 'react'
import LogInfo from '../Main/LogInfo.js'
import styles from '../Main/styles'
import { recurringStatus } from '../Main/constant.js'
import { denied } from '../Main/configuration.js'

export default function MainCtaFooter({
  form,
  formDisabled,
  disapproveButtonText,
  approveButtonText,
  files,
  recurringDrf,
  nextStatus,
  handleConfirmationConfirm,
  setShowUnableToProcess,
  isSaveRequired,
}) {
  const canSubmit = (approving) => {
    if (['pending', 'aged_pending', ...denied].includes(form.status) || isSaveRequired()) {
      return true
    }

    if (recurringStatus.includes(form.nextStatus)) {
      const conditions =
        form.requestorId &&
        (form.recurringDistribution?.dayRepeats || form.recurringDistribution.endDate) &&
        files.length > 0

      return conditions
    }

    let baseConditions =
      form.account &&
      form.amount &&
      form.dateReceived &&
      form.xeroPayTo?.name &&
      form.requestorId &&
      (form.expeditedPaymentNeeded === 'no' || form.expeditedDateNeeded) &&
      files.length > 0 &&
      (recurringDrf
        ? form.recurringDistribution?.dayRepeats && form.recurringDistribution?.endDate
        : true)

    if (form.status === 'denied') {
      return baseConditions && form.description
    }
    if (['written', 'recurring_written'].includes(form.status)) {
      if (
        ['pay_by_phone', 'pay_online'].includes(form.paymentType) &&
        nextStatus === 'admin_wrapped_up'
      ) {
        baseConditions = baseConditions && form.paymentSubtype
      }
      return baseConditions && form.paymentType
    }
    if (approving) {
      if (['pay_by_phone', 'pay_online'].includes(form.paymentType)) {
        if (!form.instructions) {
          return false
        }

        if (nextStatus == 'admin_wrapped_up' && !form.paymentSubtype) {
          return false
        }
      }

      return baseConditions && form.paymentType && form.description
    } else {
      return baseConditions
    }
  }
  const showDisapproveBtn = () => {
    if (
      nextStatus === 'admin_wrapped_up' &&
      ['check_in_house', 'cashier_check'].includes(form.paymentType)
    ) {
      return true
    }
    return !denied.includes(form.status)
  }
  return (
    <>
      <div className={`${styles.submitButtons} ${styles.submitButtonsJustify}`}>
        {form?.xeroLink ? (
          <XeroLink xeroUrl={form.xeroLink} description={'View Bill in Xero'} />
        ) : (
          <div></div>
        )}
        {(!formDisabled || (formDisabled && ['admin_wrapped_up'].includes(nextStatus))) && (
          <section className={styles.submitButtons}>
            {showDisapproveBtn() && (
              <button
                className={styles.buttonDanger}
                id="reject"
                onClick={() => setShowUnableToProcess(true)}
              >
                <span>{disapproveButtonText}</span>
              </button>
            )}

            {approveButtonText && (
              <button
                disabled={!canSubmit(true)}
                className={styles.button}
                type="submit"
                onClick={(event) => handleConfirmationConfirm(event)}
              >
                <span>{approveButtonText}</span>
              </button>
            )}
          </section>
        )}
      </div>
      {form.events && form.events.length > 0 && (
        <LogInfo
          events={form.events}
          headers={[
            { key: 'createdAt', value: 'When', className: styles.logTableHeader },
            { key: 'createdBy', value: 'Who', className: styles.logTableHeader },
            { key: 'description', value: 'Action', className: styles.logTableHeader },
          ]}
        />
      )}
    </>
  )
}
